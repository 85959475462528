import React from "react";

import { AffiliateListingItem } from "../components/AffiliateListingItem";
import {
  Container,
  Layout,
} from "../components/page";
import { SEO } from "../components/metadata/index";

import { PostsGrid } from "../components/Grid";

var affiliates = [
  {
    "title": "Robinhood",
    "img": "robinhood.png",
    "affiliateLink": "http://join.robinhood.com/junhanz",
    "excerpt": "Robinhood is completely free to use to buy stocks, ETFs and even... bitcoins.",
  },
  {
    "title": "Betterment",
    "img": "betterment.png",
    "affiliateLink": "https://betterment.com/?referral_key=junhanzhu",
    "excerpt": "Tech-driven, personalized investing to help you make a plan and build wealth.",
  },
  {
    "title": "Chase Freedom/Unlimited",
    "img": "chase.jpg",
    "affiliateLink": "https://www.referyourchasecard.com/18j/M07IIAYW82",
    "excerpt": "Earn $100 with each credit card application, it\"s a $200 in total.",
  },
  {
    "title": "Discover",
    "img": "discover.png",
    "affiliateLink": "https://refer.discover.com/s/JUNHAN22",
    "excerpt": "$100 for you, $100 for me.",
  },
  {
    "title": "Honey",
    "img": "honey.jpeg",
    "affiliateLink": "https://joinhoney.com/ref/xl7hoho",
    "excerpt": "Honey provides coupons automatically when shopping online. 500 Gold ($5) each time sign up and make a qualifying purchase.",
  },
]

const Affliates = () => (
  <Layout>
    <SEO title="/affliates" />
    <Container>
      <PostsGrid>
        {affiliates.map((node) => {
          return <AffiliateListingItem node={node} key={node.title} />
        })}
      </PostsGrid>
    </Container>
  </Layout>
)

export default Affliates
