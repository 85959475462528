import React from "react";
import {
  ListingItemWrapper,
  ListingItemCover,
  ListingItemPreview,
} from './ListingItem'
import { PostLinkExternal } from "./animated/index";
import PropTypes from "prop-types";


function AffiliateListingItem({ node }) {
  return (
    <ListingItemWrapper>
      <PostLinkExternal
        title={node.title}
        to={node.affiliateLink}>
        <ListingItemCover coverPath={`/pages/affiliates/${node.img}`} />
        <ListingItemPreview frontmatter={node} />
      </PostLinkExternal>
    </ListingItemWrapper>
  )
}

AffiliateListingItem.propTypes = {
  node: PropTypes.shape({
    affiliateLink: PropTypes.string,
    excerpt: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
  })
}

export { AffiliateListingItem }
